import React from 'react';
import clsx from 'clsx';
// gatsby
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
// material
import { Box, Grid, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import { BoxWithHeader, ContentBox, Logo, Hero, ButtonsSlick } from 'components';
// types
import { HeroProps } from 'components/Hero/Hero';
import { CarouselButtonItemProps } from 'types/carousel';
// hooks
import { usePath } from 'hooks';
//
import { stylesRoot } from './styles';

type ImageProps = HeroProps & {
  description?: string;
};

type conentButton = {
  text: string;
  link?: string;
  icon?: string;
};

interface TokenizationProps {
  header?: string;
  description?: string;
  topButtons: CarouselButtonItemProps[];
  contentButtons?: conentButton[];
  imageBottom?: ImageProps;
  smallScreen?: boolean;
}

interface TokenizationAllProps extends TokenizationProps {
  className?: string;
}

const PREFIX = 'Tokenization';
const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  contentBox: `${PREFIX}-contentBox`,
  image: `${PREFIX}-image`,
  logo: `${PREFIX}-logo`,
  topButtonsBox: `${PREFIX}-topButtonsBox`,
  buttonsBox: `${PREFIX}-buttonsBox`,
  button: `${PREFIX}-button`,
  buttonLink: `${PREFIX}-buttonLink`,
  leftTopLine: `${PREFIX}-leftTopLine`,
  rightTopLine: `${PREFIX}-rightTopLine`,
};

const StyledRoot = styled(ContentBox, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const Tokenization = (props: TokenizationAllProps) => {
  const {
    className: classNameProp,
    header,
    description,
    topButtons,
    contentButtons,
    imageBottom,
    smallScreen,
  } = props;
  const { findPagePath } = usePath();

  const dataQuery = useStaticQuery(graphql`
    query {
      leftTopLineImage: file(relativePath: { eq: "product/line-hm2.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
      rightTopLineImage: file(relativePath: { eq: "product/line-hm.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
    }
  `);

  return (
    <StyledRoot className={clsx(classes.root, classNameProp)}>
      <BoxWithHeader
        header={header}
        headerProps={{ variant: 'h1', component: 'h2' }}
        subheaderProps={{ color: 'primary', variant: 'subtitle1', component: 'h3' }}
        subheader={description}
        textAlign="center"
      >
        <div className={classes.content}>
          <Grid container spacing={smallScreen ? 4 : 2.5} justifyContent="center">
            <Grid item xs={12} sm={12}>
              <Grid
                container
                rowSpacing={smallScreen ? 2 : 0}
                columnSpacing={!smallScreen ? 3 : 0}
                justifyContent="center"
                className={classes.topButtonsBox}
              >
                <ButtonsSlick items={topButtons} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ContentBox
                className={classes.contentBox}
                showBefore={false}
                spaceTop={false}
                spaceBottom={false}
                background="default"
                textAlign="center"
              >
                <GatsbyImage
                  image={dataQuery.leftTopLineImage.childImageSharp.gatsbyImageData}
                  alt="line"
                  title="line"
                  imgStyle={{ objectFit: 'contain' }}
                  className={classes.leftTopLine}
                />
                <GatsbyImage
                  image={dataQuery.rightTopLineImage.childImageSharp.gatsbyImageData}
                  alt="line"
                  title="line"
                  imgStyle={{ objectFit: 'contain' }}
                  className={classes.rightTopLine}
                />
                <Logo center={true} size="large" className={classes.logo} />
                <Grid
                  container
                  rowSpacing={smallScreen ? 3 : 0}
                  columnSpacing={!smallScreen ? 3 : 0}
                  justifyContent="center"
                  className={classes.buttonsBox}
                >
                  {contentButtons &&
                    contentButtons.length &&
                    contentButtons.map((button: conentButton, index: number) => (
                      <Grid key={index} item xs={12} md="auto">
                        {button.link?.length ? (
                          <Button
                            component={GatsbyLink}
                            variant="text"
                            to={findPagePath(button.link)}
                            fullWidth={smallScreen}
                            className={classes.buttonLink}
                          >
                            {button.text}
                          </Button>
                        ) : (
                          <Button variant="text" fullWidth={smallScreen} className={classes.button}>
                            {button.text}
                          </Button>
                        )}
                      </Grid>
                    ))}
                </Grid>
                {imageBottom && (
                  <Box className={classes.image}>
                    <Hero
                      heroImg={imageBottom?.heroImg}
                      imageName={imageBottom?.imageName}
                      alt={imageBottom.alt}
                      title={imageBottom?.title}
                    />
                  </Box>
                )}
              </ContentBox>
            </Grid>
          </Grid>
        </div>
      </BoxWithHeader>
    </StyledRoot>
  );
};

export default Tokenization;
