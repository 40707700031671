import React, { ReactNode } from 'react';
import clsx from 'clsx';
// material
import { Box, Button, Hidden, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// gatsby
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
// components
import { TextAndItemSection } from 'components';
import { ArrowRight as ButtonIcon } from 'components/CustomIcon';
// types
import { GatsbyLinkProps } from 'gatsby-link';
//
import { stylesRoot } from './styles';

interface DemocraticAccessProps {
  header?: string;
  description?: string;
  link?: {
    text: string;
    href: GatsbyLinkProps<any>['to'];
  };
  content?: ReactNode;
  smallScreen?: boolean;
}

interface DemocraticAccessAllProps extends DemocraticAccessProps {
  className?: string;
}

const PREFIX = 'DemocraticAccess';
const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  decorationImg: `${PREFIX}-decorationImg`,
  firstDecorationImg: `${PREFIX}-firstDecorationImg`,
  secondDecorationImg: `${PREFIX}-secondDecorationImg`,
  description: `${PREFIX}-description`,
};

const StyledRoot = styled(TextAndItemSection, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const DemocraticAccess = (props: DemocraticAccessAllProps) => {
  const { className: classNameProp, header, description, smallScreen, link, content } = props;

  const data = useStaticQuery(graphql`
    query {
      firstDecoration: file(relativePath: { eq: "coin.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 124)
        }
      }
      secondDecoration: file(relativePath: { eq: "planete.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 94)
        }
      }
    }
  `);

  const renderHeaderAndContent = (): ReactNode => (
    <>
      {description && (
        <Typography
          color="textSecondary"
          variant="h6"
          component="h4"
          className={classes.description}
        >
          {description}
        </Typography>
      )}
      {content}
    </>
  );

  const renderLink = (): ReactNode => (
    <>
      {link && (
        <Button component={GatsbyLink} to={link.href} endIcon={<ButtonIcon />} size="large">
          {link.text}
        </Button>
      )}
    </>
  );

  return (
    <StyledRoot
      header={header}
      headerProps={{ variant: 'h2', component: 'h2' }}
      divider
      classesChildren={{ root: clsx(classes.root, classNameProp), content: classes.content }}
      additionalChildren={<>{smallScreen ? renderLink() : renderHeaderAndContent()}</>}
    >
      {smallScreen ? renderHeaderAndContent() : renderLink()}

      <Box>
        {data.firstDecoration && (
          <Hidden mdDown>
            <div className={clsx(classes.decorationImg, classes.firstDecorationImg)}>
              <GatsbyImage
                image={data.firstDecoration.childImageSharp.gatsbyImageData}
                alt=""
                title=""
              />
            </div>
          </Hidden>
        )}
        {data.secondDecoration && (
          <Hidden smDown>
            <div className={clsx(classes.decorationImg, classes.secondDecorationImg)}>
              <GatsbyImage
                image={data.secondDecoration.childImageSharp.gatsbyImageData}
                alt=""
                title=""
              />
            </div>
          </Hidden>
        )}
      </Box>
    </StyledRoot>
  );
};

export default DemocraticAccess;
