import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, palette, shape, spacing } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        [`& .${classes.contentInner}`]: {
          textAlign: 'center',
          [breakpoints.up('md')]: {
            textAlign: 'left',
          },
          '& > .Heading-contentInner': {
            [breakpoints.down('md')]: {
              marginBottom: '0px !important',
            },
          },
          '& > *:not(:last-child)': {
            marginBottom: spacing(4),
            [breakpoints.up('sm')]: {
              marginBottom: spacing(5.5),
            },
            [breakpoints.up('lg')]: {
              marginBottom: spacing(7),
            },
          },

          [`& .${classes.header}`]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: breakpoints.values.sm,

            [breakpoints.down('md')]: {
              marginBottom: spacing(0),
            },
          },
        },

        [`& .${classes.headerText}`]: {
          textAlign: 'center',
          [breakpoints.up('md')]: {
            textAlign: 'left',
          },
        },
        [`& .${classes.logotypes}`]: {
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: breakpoints.values.sm,
        },
        [`& .${classes.logotypesAdditionalContent}`]: {
          display: 'inline-flex',
          minHeight: 32,
          alignItems: 'center',
          position: 'relative',
          backgroundColor: palette.primary.main,
          color: palette.primary.contrastText,
          borderRadius: shape.borderRadius,
          padding: spacing(0.5, 1),
          '& span': {
            display: 'block',
            lineHeight: 1.3,
          },
        },
        [`& .${classes.logotypesAdditionalContentIcon}`]: {
          position: 'absolute',
          left: '100%',
          bottom: '100%',
          marginLeft: spacing(-0.75),
          marginBottom: spacing(-0.5),
        },
        [`& .${classes.logotypesSeparator}`]: {
          position: 'absolute',
          top: '50%',
          right: 0,
          color: palette.action.disabledBackground,
          fontSize: 20,
          transform: 'translateX(50%) translateY(-50%)',
        },
        [`& .${classes.logotypesImageListItem}`]: {
          '&:last-child': {
            [`& .${classes.logotypesSeparator}`]: {
              display: 'none',
            },
          },
        },
        [`& .${classes.buttonsWrapper}`]: {
          [breakpoints.down('md')]: {
            marginBottom: spacing(4),
          },
          '& > *': {
            maxWidth: breakpoints.values.sm,
            margin: `${spacing(0.75)} auto`,
            '&:not(:last-child)': {
              [breakpoints.up('md')]: {
                marginRight: spacing(2),
              },
            },
          },
        },
        [`& .${classes.benefitText}`]: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          [breakpoints.up('md')]: {
            justifyContent: 'flex-start',
          },
          [`& .${classes.decorationImg}`]: {
            marginRight: spacing(2),
            width: 68,
            height: 66,
            [breakpoints.down('md')]: {
              display: 'none',
            },
          },
          '& button': {
            marginLeft: 2,
            color: palette.primary.lighter,
            '& svg': {
              fontSize: 20,
            },
          },
        },
        [`& .${classes.benefitDesktop}`]: {
          [breakpoints.down('md')]: {
            display: 'none',
          },
        },
        [`& .${classes.buttonsDesktop}`]: {
          [breakpoints.down('md')]: {
            display: 'none',
          },
        },
        [`& .${classes.showMobile}`]: {
          [breakpoints.up('md')]: {
            display: 'none',
          },
        },

        [`& .${classes.decorationImg}`]: {
          position: 'relative',
          '& .gatsby-image-wrapper': {
            display: 'block !important',
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 4,
            bottom: 4,
            left: 4,
            right: 4,
            borderRadius: shape.borderRadius,
            background: palette.background.default,
          },
        },
      },
    },
  };
};
