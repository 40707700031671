import { Theme } from '@mui/material/styles';

export const stylesContent = (theme: Theme) => {
  const { breakpoints, spacing } = theme;
  return {
    '&': {
      position: 'relative',
      padding: spacing(4, 0),
      [breakpoints.up('md')]: {
        padding: spacing(6, 0),
      },
    },
  };
};

export const stylesButton = (theme: Theme) => {
  const { spacing } = theme;
  return {
    '&': {
      marginTop: spacing(4),
    },
  };
};
