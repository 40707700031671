import React from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
// components
import { ContentBox, HeadingTextWithSpaceLine, Logotypes } from 'components';
// types
import { ImageTypeArray } from 'types/image';
//
import { stylesRoot } from './styles';

interface WroteAboutUsProps {
  title?: string;
  images?: ImageTypeArray;
  smallScreen?: boolean;
}

interface WroteAboutUsAllProps extends WroteAboutUsProps {
  className?: string;
}

const PREFIX = 'WroteAboutUs';
const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
};

const StyledRoot = styled(ContentBox, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const WroteAboutUs = (props: WroteAboutUsAllProps) => {
  const { className: classNameProp, title, images, smallScreen } = props;

  return (
    <StyledRoot className={clsx(classes.root, classNameProp)}>
      {title && (
        <Container className={classes.title}>
          <HeadingTextWithSpaceLine>{title}</HeadingTextWithSpaceLine>
        </Container>
      )}

      {images && (
        <ContentBox spaceBottom={smallScreen}>
          <Logotypes
            images={images}
            greyScale="colorOnHover"
            gap={smallScreen ? 5 : 12}
            colsXs={3}
            colsSm={5}
          />
        </ContentBox>
      )}
    </StyledRoot>
  );
};

export default WroteAboutUs;
