import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
// i18n
import '../../locales/i18n';
// hooks
import { useTheme } from '@mui/system';
// components
import { Header, Seo, Footer } from 'components';
// types
import { Metadata } from 'types/metadata';
// pages components
import { ContactBelt } from 'pages-components/home';

interface LayoutProps {
  children: React.ReactNode;
  metadata?: Metadata;
  mainPhoto?: string;
  path?: string;
  userLang?: string;
  locale?: string;
  contact?: boolean;
}

const MainLayout = ({
  children,
  metadata,
  mainPhoto,
  userLang,
  locale,
  path,
  contact,
}: LayoutProps) => {
  const theme = useTheme();

  const gradientFillArray = () =>
    theme.palette.gradients.button
      .replace('linear-gradient(to right, ', '')
      .replace(')', '')
      .split(', ');
  const gradientButtonFill = gradientFillArray();
  const windowGlobal = typeof window !== 'undefined' && window;
  const handleSendEventGtm = (event: any) => {
    window.dataLayer.push({ event: 'gatsby-click', targetClick: event?.target });
  };

  useEffect(() => {
    if (windowGlobal) {
      if (window.HubSpotConversations && window.gtag) {
        window.HubSpotConversations.on('conversationStarted', (payload: any) => {
          if (window.gtag) {
            window.gtag('event', 'click', { conversationId: payload.conversation.conversationId });
          }
        });
      }
      if (window.dataLayer) {
        window.dataLayer = window.dataLayer || [];
        window.addEventListener('click', handleSendEventGtm);
      }
    }
    return () => {
      if (windowGlobal) {
        if (window.dataLayer) {
          window.removeEventListener('click', handleSendEventGtm);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CookiesProvider>
        <Seo
          metadata={metadata}
          mainPhoto={mainPhoto}
          userLang={userLang}
          path={path}
          locale={locale}
        />
        <Header />

        <main>{children}</main>

        {contact && <ContactBelt />}

        <Footer />

        <svg
          aria-hidden="true"
          focusable="false"
          style={{ width: 0, height: 0, position: 'absolute' }}
        >
          <linearGradient
            id="button-gradient-fill"
            x1={gradientButtonFill[0].split(' ')[1]}
            x2={gradientButtonFill[2].split(' ')[1]}
          >
            <stop offset="0%" stopColor={gradientButtonFill[0].split(' ')[0]} />
            <stop offset="50%" stopColor={gradientButtonFill[1].split(' ')[0]} />
            <stop offset="100%" stopColor={gradientButtonFill[2].split(' ')[0]} />
          </linearGradient>
        </svg>
      </CookiesProvider>
    </>
  );
};

MainLayout.defaultProps = {
  contact: true,
} as Partial<LayoutProps>;

export default MainLayout;
