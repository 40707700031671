import React from 'react';
// layouts
import { MainLayout } from 'layouts';
// material
import { Theme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';
// pages components
import { Capabilities, DemocraticAccess, Heading, Tokenization } from 'pages-components/home';
import { TextAndImage, WroteAboutUs } from 'pages-components/common';
// hooks
import { useLocales, usePath } from 'hooks';

const HomeLayout = ({ frontMatter, userLang, path, locale }: any) => {
  const { translate: t } = useLocales();
  const { findPagePath } = usePath();
  const miniScreen = useMediaQuery(({ breakpoints }: Theme) => breakpoints.down('sm'));
  const smallScreen = useMediaQuery(({ breakpoints }: Theme) => breakpoints.down('md'));

  const {
    democraticAccess,
    friendlyEcosystem,
    spaceForCommunity,
    supportDevelopment,
    tokenization,
    wroteAboutUs,
    capabilities,
  } = frontMatter.sections;

  return (
    <MainLayout metadata={frontMatter?.metadata} userLang={userLang} path={path} locale={locale}>
      {frontMatter?.heading && (
        <Heading
          title={frontMatter?.heading?.title}
          subtitle={frontMatter?.heading?.subtitle}
          titleChangeWords={frontMatter?.heading?.titleChangeWords}
          showImages={frontMatter?.heading?.logos?.show}
          images={frontMatter?.heading?.logos?.images}
          additionalContent={frontMatter?.heading?.logos?.additionalContent}
          earn={{
            text: t('pages.home.hero.bottomText'),
            description: t('pages.home.hero.tooltip'),
          }}
          slider={frontMatter.heading.slider}
          miniScreen={miniScreen}
          smallScreen={smallScreen}
        />
      )}
      {tokenization?.show && (
        <Tokenization
          header={t('pages.home.tokenization.title')}
          description={t('pages.home.tokenization.description')}
          topButtons={tokenization.topButtons}
          contentButtons={tokenization.contentButtons}
          imageBottom={{
            heroImg: tokenization.imageBottom,
            imageName: tokenization.imageBottom?.src.name,
            title: tokenization.imageBottom?.title,
            alt: tokenization.imageBottom.alt,
            description: t('pages.home.tokenization.firstImageDescription'),
          }}
          smallScreen={smallScreen}
        />
      )}
      {democraticAccess?.show && (
        <DemocraticAccess
          header={t('pages.home.democraticAccess.title')}
          description={t('pages.home.democraticAccess.description')}
          content={
            t('pages.home.democraticAccess.content') && (
              <Typography>{t('pages.home.democraticAccess.content')}</Typography>
            )
          }
          link={{
            text: t('pages.home.democraticAccess.textLink'),
            href: findPagePath('what-is-tokenization-and-how-can-you-use-it-in-practise'),
          }}
          smallScreen={smallScreen}
        />
      )}
      {friendlyEcosystem?.show && (
        <TextAndImage
          header={t('pages.home.friendlyEcosystem.title')}
          description={t('pages.home.friendlyEcosystem.description')}
          image={friendlyEcosystem?.image}
          content={
            t('pages.home.friendlyEcosystem.content') && (
              <Typography>{t('pages.home.friendlyEcosystem.content')}</Typography>
            )
          }
          revertColumns={!smallScreen}
        />
      )}
      {spaceForCommunity?.show && (
        <TextAndImage
          header={t('pages.home.spaceForCommunity.title')}
          description={t('pages.home.spaceForCommunity.description')}
          image={spaceForCommunity?.image}
          content={
            t('pages.home.spaceForCommunity.content') && (
              <Typography>{t('pages.home.spaceForCommunity.content')}</Typography>
            )
          }
        />
      )}
      {wroteAboutUs?.show && (
        <WroteAboutUs
          title={t('pages.home.wroteAboutUs.title')}
          images={wroteAboutUs?.images}
          smallScreen={smallScreen}
        />
      )}
      {capabilities?.show && (
        <Capabilities
          subheader={t('pages.home.capabilities.subHeader')}
          header={t('pages.home.capabilities.header')}
          smallScreen={smallScreen}
          sxScreen={miniScreen}
        />
      )}
      {supportDevelopment?.show && (
        <TextAndImage
          header={t('pages.home.supportDevelopment.title')}
          subheader={t('pages.home.supportDevelopment.subtitle')}
          description={t('pages.home.supportDevelopment.description')}
          image={supportDevelopment?.image}
          content={
            <>
              {t('pages.home.supportDevelopment.content') && (
                <Typography>{t('pages.home.supportDevelopment.content')}</Typography>
              )}
            </>
          }
        />
      )}
    </MainLayout>
  );
};

export default HomeLayout;
