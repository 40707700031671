import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, palette, shape, spacing } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        [`& .${classes.content}`]: {
          position: 'relative',
        },
        [`& .${classes.contentBox}`]: {
          paddingTop: spacing(6),
          position: 'relative',
          overflow: 'hidden',
          borderRadius: shape.borderRadiusLg,
          [breakpoints.up('sm')]: {
            paddingTop: spacing(8),
          },
        },
        [`& .${classes.image}`]: {
          margin: `${spacing(4)} auto 0 auto`,
          maxWidth: '1000px',
          [breakpoints.up('sm')]: {
            margin: `${spacing(8)} auto 0 auto`,
          },
        },
        [`& .${classes.logo}`]: {
          display: 'flex',
        },
        [`& .${classes.topButtonsBox}`]: {
          margin: `0 0 ${spacing(0)} 0`,
          width: '100%',

          [breakpoints.up('sm')]: {
            margin: `0 0 ${spacing(2)} 0`,
          },
        },
        [`& .${classes.buttonsBox}`]: {
          margin: `${spacing(1)} 0 ${spacing(7)} 0`,
          width: '100%',

          [breakpoints.up('sm')]: {
            margin: `${spacing(8)} 0`,
          },
        },

        [`& .${classes.button}`]: {
          backgroundColor: palette.background.paper,
          cursor: 'default',
        },
        [`& .${classes.buttonLink}`]: {
          backgroundColor: palette.background.paper,
          cursor: 'pointer',
        },
        [`& .${classes.leftTopLine}`]: {
          display: 'block',
          width: 148,
          position: 'absolute',
          left: 0,
          top: '0',
          [breakpoints.up('sm')]: {
            width: 198,
            left: 0,
            top: '0',
          },
        },
        [`& .${classes.rightTopLine}`]: {
          width: 148,
          position: 'absolute',
          right: -0,
          top: 0,
          display: 'none',
          [breakpoints.up('sm')]: {
            display: 'block',
            width: 198,
          },
        },
      },
    },
  };
};
