import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, spacing, typography } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        marginBottom: 0,
        [`& .${classes.content}`]: {
          position: 'relative',
          marginBottom: 0,
        },
        [`& .${classes.decorationImg}`]: {
          position: 'absolute',
          overflow: 'hidden',
          marginBottom: 0,
          '& img': {
            display: 'block',
          },
        },
        [`& .${classes.firstDecorationImg}`]: {
          bottom: 0,
          left: spacing(3),
          height: 72,
        },
        [`& .${classes.secondDecorationImg}`]: {
          bottom: 0,
          right: spacing(6),
          transform: 'translateY(50%)',
          [breakpoints.up('md')]: {
            right: spacing(14),
          },
        },
        [`& .${classes.description}`]: {
          fontWeight: typography.fontWeightMedium,
        },
      },
    },
  };
};
