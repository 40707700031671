import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, spacing } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        position: 'relative',
        overflow: 'hidden',

        [`& .${classes.nativeForms}`]: {
          '& .nf-submit-button-container': {
            '& .nf-submit-button': {
              width: 'auto',
              margin: '0 auto',
              paddingLeft: spacing(4),
              paddingRight: spacing(4),
              [breakpoints.up('md')]: {
                minWidth: 240,
              },
            },
          },
        },

        [`& .${classes.contentRoot}`]: {
          position: 'relative',
          zIndex: 1,
        },
        [`& .${classes.content}`]: {
          '& > *:not(:last-child)': {
            marginBottom: spacing(3),
            [breakpoints.up('sm')]: {
              marginBottom: spacing(3.5),
            },
            [breakpoints.up('lg')]: {
              marginBottom: spacing(4),
            },
          },
        },
        [`& .${classes.newsletterContent}`]: {
          maxWidth: '500px',
          margin: 'auto',
          marginTop: spacing(3),
          [breakpoints.up('sm')]: {
            marginTop: spacing(6),
          },
        },
        [`& .${classes.image}`]: {
          display: 'flex',
          flexDirection: 'column-reverse',
          position: 'absolute',
          pointerEvents: 'none',
          '& .gatsby-image-wrapper': {
            display: 'block !important',
          },
        },
        [`& .${classes.arrowImage}`]: {
          top: 0,
          left: -100,
          width: 200,
          [breakpoints.up('md')]: {
            top: spacing(8),
          },
          [breakpoints.up('xl')]: {
            top: spacing(5),
            maxHeight: 110,
            left: '5%',
          },
        },
        [`& .${classes.leftLineImage}`]: {
          bottom: -30,
          left: -430,
          width: 500,
          [breakpoints.up('md')]: {
            left: -300,
          },
          [breakpoints.up('xl')]: {
            maxHeight: '73%',
            left: -220,
            width: 600,
          },
        },
        [`& .${classes.rightTopLineImage}`]: {
          top: -80,
          right: -50,
          width: 150,
          [breakpoints.up('md')]: {
            width: 200,
            top: -40,
            right: 0,
          },
        },
        [`& .${classes.rightBottomLineImage}`]: {
          bottom: -150,
          right: -60,
          width: 275,
          [breakpoints.up('md')]: {
            bottom: -100,
            right: 0,
          },
        },
      },
    },
  };
};
