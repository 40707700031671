import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import plLocales from '../../locales/pl/translations.json';
import enLocales from '../../locales/en/translations.json';

// ----------------------------------------------------------------------

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // detection: {
    //   cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN,
    //   caches: ['cookie', 'localStorage'],
    // },
    resources: {
      pl: { translations: plLocales },
      en: { translations: enLocales },
    },
    // lng: localStorage.getItem('i18next') || 'en', // ? language detector take care of lng
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
