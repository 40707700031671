import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme) => {
  const { typography, palette, spacing } = theme;
  return {
    '&': {
      boxShadow: `0 2px 1px -1px ${palette.grey[100]}`,
      padding: spacing(6, 0),
      fontWeight: typography.fontWeightMedium,
    },
  };
};

export const stylesText = (theme: Theme) => {
  const { breakpoints } = theme;
  return {
    '&': {
      textAlign: 'center',
      [breakpoints.up('md')]: {
        textAlign: 'left',
      },
    },
  };
};
