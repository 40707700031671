import React, { ReactNode } from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
// components
import { TextAndItemSection } from 'components';
// types
import { BoxWithHeaderProps } from 'components/BoxWithHeader/BoxWithHeader';
import { ContentBoxProps } from 'components/ContentBox/ContentBox';
import { ImageType } from 'types/image';
//
import { stylesRoot } from './styles';

interface TextAndImageProps {
  header?: string;
  subheader?: string;
  description?: ReactNode;
  image?: ImageType;
  imageAddon?: ReactNode;
  content?: ReactNode;
  revertColumns?: boolean;
  spaceSize?: ContentBoxProps['spaceSize'];
  spaceTop?: ContentBoxProps['spaceTop'];
  spaceBottom?: ContentBoxProps['spaceBottom'];
  itemsSpace?: BoxWithHeaderProps['spaceHeader'];
}

interface TextAndImageAllProps extends TextAndImageProps {
  className?: string;
}

const PREFIX = 'TextAndImage';
const classes = {
  root: `${PREFIX}-root`,
  description: `${PREFIX}-description`,
};

const StyledRoot = styled(TextAndItemSection, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const TextAndImage = (props: TextAndImageAllProps) => {
  const {
    className: classNameProp,
    header,
    subheader,
    description,
    image,
    imageAddon,
    content,
    revertColumns,
    spaceSize,
    spaceTop,
    spaceBottom,
    itemsSpace,
  } = props;

  return (
    <StyledRoot
      header={header}
      eaderProps={{ variant: 'h2', component: 'h2' }}
      subheader={subheader}
      image={image}
      imageAddon={imageAddon}
      revertColumns={revertColumns}
      invertHeader
      itemsSpace={itemsSpace}
      spaceSize={spaceSize}
      spaceTop={spaceTop}
      spaceBottom={spaceBottom}
      classesChildren={{
        root: clsx(classes.root, classNameProp),
      }}
    >
      {description && (
        <Box className={classes.description}>
          {typeof description === 'string' ? (
            <Typography color="textSecondary" variant="h6" component="h4">
              {description}
            </Typography>
          ) : (
            description
          )}
        </Box>
      )}
      {content}
    </StyledRoot>
  );
};

TextAndImage.defaultProps = {
  spaceSize: 'large',
  spaceTop: true,
  spaceBottom: true,
} as Partial<TextAndImageAllProps>;

export default TextAndImage;
