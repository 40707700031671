import * as React from 'react';
// gatsby
import { Link as GatsbyLink } from 'gatsby';
// material
import { styled, Theme } from '@mui/material/styles';
import { Typography, Button, Container, useMediaQuery, Grid } from '@mui/material';
import { Email as ButtonIcon } from '@mui/icons-material';
// components
import { TextGradient } from 'components';
// hooks
import { useLocales, usePath } from 'hooks';
//
import { stylesRoot, stylesText } from './styles';

const PREFIX = 'ContactBelt';
const StyledRoot = styled('div', { name: PREFIX, slot: 'root' })(({ theme }) => stylesRoot(theme));
const StyledText = styled(Grid, { name: PREFIX, slot: 'text' })(({ theme }) => stylesText(theme));

const ContactBelt = () => {
  const { translate: t } = useLocales();
  const { findPagePath } = usePath();
  const smallScreen = useMediaQuery(({ breakpoints }: Theme) => breakpoints.down('md'));

  return (
    <StyledRoot>
      <Container>
        <Grid container spacing={smallScreen ? 4 : 2} alignItems="center">
          <StyledText item xs={12} md={8}>
            <Typography variant="h3" component="h2" fontWeight="500">
              {t('contactBelt.text')}{' '}
              <TextGradient gradientLine="button" gradientText="text">
                {t('contactBelt.word')}
              </TextGradient>
            </Typography>
          </StyledText>

          <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
            <Button
              component={GatsbyLink}
              size="large"
              variant="outlined"
              startIcon={<ButtonIcon />}
              to={findPagePath('contact')}
            >
              {t('contactBelt.button')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </StyledRoot>
  );
};

export default ContactBelt;
