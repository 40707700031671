import React, { useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
// material
import { styled } from '@mui/material/styles';
import { Button, Grid } from '@mui/material';
// components
import { BoxWithHeader, CapabilityItem, ContentBox } from 'components';
import {
  HomeTrendUp as HomeTrendUpIcon,
  Building4 as Building4Icon,
  Flash1 as FlashIcon,
  Brush as BrushIcon,
  Ship as ShipIcon,
  Game as GameIcon,
  Copyright as CopyrightIcon,
  Diamonds as DiamondsIcon,
  ArrowDown as ButtonIcon,
} from 'components/CustomIcon';
// hooks
import { useLocales } from 'hooks';
//
import { stylesContent, stylesButton } from './styles';

interface CapabilitiesProps {
  className?: string;
  header?: string;
  subheader?: string;
  smallScreen?: boolean;
  sxScreen?: boolean;
}

interface item {
  title: string;
  icon: ReactJSXElement;
  showMobile: boolean;
}

const PREFIX = 'Capabilities';
const StyledContent = styled('div', { name: PREFIX, slot: 'content' })(({ theme }) =>
  stylesContent(theme),
);
const StyledButton = styled(Button, { name: PREFIX, slot: 'button' })(({ theme }) =>
  stylesButton(theme),
);

const Capabilities = (props: CapabilitiesProps) => {
  const { className: classNameProp, header, subheader, smallScreen, sxScreen } = props;
  const { translate: t } = useLocales();
  const [itemList, setItemList] = useState([
    {
      title: t('pages.home.capabilities.item1'),
      icon: <HomeTrendUpIcon />,
      showMobile: true,
    },
    {
      title: t('pages.home.capabilities.item2'),
      icon: <Building4Icon />,
      showMobile: true,
    },
    {
      title: t('pages.home.capabilities.item3'),
      icon: <FlashIcon />,
      showMobile: true,
    },
    {
      title: t('pages.home.capabilities.item4'),
      icon: <BrushIcon />,
      showMobile: true,
    },
    {
      title: t('pages.home.capabilities.item5'),
      icon: <ShipIcon />,
      showMobile: false,
    },
    {
      title: t('pages.home.capabilities.item6'),
      icon: <GameIcon />,
      showMobile: false,
    },
    {
      title: t('pages.home.capabilities.item7'),
      icon: <CopyrightIcon />,
      showMobile: false,
    },
    {
      title: t('pages.home.capabilities.item8'),
      icon: <DiamondsIcon />,
      showMobile: false,
    },
  ]);

  const renderItem = (item: item, val: number) => (
    <Grid key={val} item xs={12} sm={6} md={3} lg={3}>
      <CapabilityItem title={item?.title} icon={item?.icon} />
    </Grid>
  );

  const updateItemList = () => {
    const newList = [...itemList];
    newList.map((item) => (item.showMobile = true));
    setItemList(newList);
  };

  return (
    <ContentBox className={classNameProp} background="default">
      <BoxWithHeader
        header={header}
        headerProps={{ variant: 'h1', component: 'h2' }}
        subheader={subheader}
        subheaderProps={{ color: 'primary', variant: 'subtitle1', component: 'h3' }}
        textAlign="center"
      >
        <StyledContent>
          <Grid container spacing={smallScreen ? 2 : 3}>
            {sxScreen
              ? itemList
                  .filter((item) => (sxScreen ? item.showMobile : true))
                  .map((item, val) => renderItem(item, val))
              : itemList.map((item, val) => renderItem(item, val))}
          </Grid>
          {sxScreen && itemList.filter((item) => !item.showMobile).length ? (
            <StyledButton
              variant="text"
              size="small"
              endIcon={<ButtonIcon color="disabled" />}
              color="inherit"
              onClick={() => updateItemList()}
            >
              {t('common.showMore')}
            </StyledButton>
          ) : null}
        </StyledContent>
      </BoxWithHeader>
    </ContentBox>
  );
};

export default Capabilities;
