import React, { ReactNode } from 'react';
import clsx from 'clsx';
// gatsby
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, IconButton, Tooltip, Typography, Grid } from '@mui/material';
import { HelpRounded as HelpIcon } from '@mui/icons-material';
// components
import { HeroCarousel, HeroSection, Logotypes, ChangeWord } from 'components';
import { DecorationClose as SeparatorIcon, Splash as SplashIcon } from 'components/CustomIcon';
// hooks
import { useLocales, usePath } from 'hooks';
// types
import { ImageType, ImageTypeArray } from 'types/image';
//
import { stylesRoot } from './styles';

interface Slide {
  [x: string]: any;
  text: string;
  image: ImageType;
  category: string;
}

interface HeadingProps {
  title?: string;
  subtitle?: string;
  titleChangeWords?: string[];
  showImages?: boolean;
  images?: ImageTypeArray;
  additionalContent?: ReactNode;
  earn?: {
    text: string;
    description: string;
  };
  slider: Slide;
  miniScreen?: boolean;
  smallScreen?: boolean;
}

interface HeadingAllProps extends HeadingProps {
  className?: string;
}

const PREFIX = 'Heading';
const classes = {
  root: `${PREFIX}-root`,
  contentInner: `${PREFIX}-contentInner`,
  header: `${PREFIX}-header`,
  headerText: `${PREFIX}-header`,
  logotypes: `${PREFIX}-logotypes`,
  logotypesAdditionalContent: `${PREFIX}-logotypesAdditionalContent`,
  logotypesAdditionalContentIcon: `${PREFIX}-logotypesAdditionalContentIcon`,
  logotypesSeparator: `${PREFIX}-logotypesSeparator`,
  logotypesImageListItem: `${PREFIX}-logotypesImageListItem`,
  buttonsDesktop: `${PREFIX}-buttonsDesktop`,
  buttonsWrapper: `${PREFIX}-buttonsWrapper`,
  benefitDesktop: `${PREFIX}-benefitDesktop`,
  benefitText: `${PREFIX}-benefitText`,
  decorationImg: `${PREFIX}-decorationImg`,
  showMobile: `${PREFIX}-showMobile`,
};

const StyledRoot = styled(HeroSection, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const Heading = (props: HeadingAllProps) => {
  const {
    className: classNameProp,
    title,
    subtitle,
    titleChangeWords,
    showImages,
    images,
    additionalContent,
    earn,
    slider,
    miniScreen,
    smallScreen,
  } = props;
  const { translate: t } = useLocales();
  const { findPagePath } = usePath();

  const data = useStaticQuery(graphql`
    query {
      decorationImage: file(relativePath: { eq: "coins.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 68)
        }
      }
    }
  `);

  const CAROUSELS = slider.map((item: Slide, index: number) => ({
    id: index,
    title: item.text,
    alt: item.image?.alt,
    imageTitle: item.image?.title,
    image: item.image?.src.publicURL,
    name: item.image.src.name,
    category: item.category,
  }));

  const renderButtons = (): ReactNode => (
    <Box className={classes.buttonsWrapper}>
      <Button
        component={GatsbyLink}
        to={findPagePath('product')}
        size="large"
        fullWidth={smallScreen}
      >
        {t('pages.home.hero.link1')}
      </Button>
    </Box>
  );

  const renderBenefitText = (): ReactNode => (
    <>
      {earn && (
        <Box className={classes.benefitText}>
          {data.decorationImage && (
            <div className={classes.decorationImg}>
              <GatsbyImage
                image={data.decorationImage.childImageSharp.gatsbyImageData}
                alt=""
                title=""
              />
            </div>
          )}
          <Typography variant={!smallScreen ? 'h4' : 'h6'} component="h2">
            {earn.text}
          </Typography>

          <Tooltip title={earn.description}>
            <IconButton size="small">
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </>
  );

  return (
    <StyledRoot spaceTop={true} classesChildren={{ root: clsx(classes.root, classNameProp) }}>
      <Grid
        container
        rowSpacing={5}
        columnSpacing={smallScreen ? 5 : 12}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} sm={11} md={7} lg={6}>
          <Box className={classes.contentInner}>
            <Box className={classes.contentInner}>
              {title && (
                <Box className={classes.header}>
                  <Typography
                    color="primary"
                    variant="subtitle1"
                    component="h2"
                    gutterBottom
                    className={classes.headerText}
                  >
                    {subtitle}
                  </Typography>
                  <Typography variant="h1" className={classes.headerText}>
                    {title}{' '}
                    <ChangeWord
                      words={titleChangeWords || []}
                      timer={5000}
                      options={{ interval: 40 }}
                      smallScreen={smallScreen}
                    />
                  </Typography>
                </Box>
              )}

              {images && showImages && (
                <Logotypes
                  gap={miniScreen ? 5 : 8}
                  colsXs={3}
                  images={images}
                  imageAddon={<SeparatorIcon className={classes.logotypesSeparator} />}
                  classesChildren={{
                    root: classes.logotypes,
                    imageListItem: classes.logotypesImageListItem,
                  }}
                  additionalContent={
                    additionalContent && (
                      <Box className={classes.logotypesAdditionalContent}>
                        <Typography component="span" variant={miniScreen ? 'caption' : 'h4'}>
                          {additionalContent}
                        </Typography>
                        {!miniScreen && (
                          <SplashIcon
                            color="info"
                            className={classes.logotypesAdditionalContentIcon}
                          />
                        )}
                      </Box>
                    )
                  }
                />
              )}

              <Box className={classes.buttonsDesktop}>{renderButtons()}</Box>
            </Box>

            <Box className={clsx(classes.contentInner, classes.benefitDesktop)}>
              {renderBenefitText()}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={8} md={5} lg={6}>
          <Box className={classes.contentInner}>
            <HeroCarousel items={CAROUSELS} />

            <Box className={classes.showMobile}>
              <>
                {renderButtons()}
                {renderBenefitText()}
              </>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default Heading;
