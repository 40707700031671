import React from 'react';
import clsx from 'clsx';
// gatsby
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyLink from 'gatsby-link';
import { GatsbyImage } from 'gatsby-plugin-image';
// material
import { styled } from '@mui/material/styles';
import { Button, Typography, Box } from '@mui/material';
import { Email as EmailIcon } from '@mui/icons-material';
// components
import { BoxWithHeader, ContentBox, Newsletter } from 'components';
// types
import { BoxWithHeaderProps } from 'components/BoxWithHeader/BoxWithHeader';
// hooks
import { useLocales, usePath } from 'hooks';
// helpers
import getNativeFormsProperties, { NativeFormsIdsType } from 'helpers/getNativeFormsUrl';
//
import { stylesRoot } from './styles';

interface ContactSectionProps {
  headerProps?: BoxWithHeaderProps['headerProps'];
  subheaderProps?: BoxWithHeaderProps['subheaderProps'];
  variant: 'contact' | 'newsletter';
}

interface ContactSectionAllProps extends ContactSectionProps {
  className?: string;
}

const PREFIX = 'ContactSection';
const classes = {
  root: `${PREFIX}-root`,
  contentRoot: `${PREFIX}-contentRoot`,
  content: `${PREFIX}-content`,
  newsletterContent: `${PREFIX}-newsletterContent`,
  image: `${PREFIX}-image`,
  arrowImage: `${PREFIX}-arrowImage`,
  leftLineImage: `${PREFIX}-leftLineImage`,
  rightTopLineImage: `${PREFIX}-rightTopLineImage`,
  rightBottomLineImage: `${PREFIX}-rightBottomLineImage`,
  nativeForms: `${PREFIX}-nativeForms`,
};

const StyledRoot = styled(ContentBox, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const StyledContent = styled(Box, { name: PREFIX, slot: 'content' })({});

const ContactSection = (props: ContactSectionAllProps) => {
  const { className: classNameProp, headerProps, subheaderProps, variant } = props;
  const { currentLang, translate: t } = useLocales();
  const { findPagePath } = usePath();

  const FORM_ID: NativeFormsIdsType = 'newsletter-investor';
  const formProperties = getNativeFormsProperties(FORM_ID, currentLang.value);

  const data = useStaticQuery(graphql`
    query {
      arrowImage: file(relativePath: { eq: "product/line-arrow.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 300)
        }
      }
      leftLineImage: file(relativePath: { eq: "product/line-l2.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
      rightTopLineImage: file(relativePath: { eq: "product/line-tr2.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
      rightBottomLineImage: file(relativePath: { eq: "product/line-br.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 275)
        }
      }
    }
  `);

  const titleText =
    variant === 'contact' ? t('contactSection.title') : t('contactSection.newsletterTitle');

  const subtitleText =
    variant === 'contact' ? t('contactSection.subtitle') : t('contactSection.newsletterSubtitle');

  return (
    <StyledRoot
      className={clsx(classes.root, classNameProp)}
      background="default"
      spaceSize="extraHuge"
    >
      <BoxWithHeader
        header={titleText}
        headerProps={headerProps}
        subheader={subtitleText}
        subheaderProps={subheaderProps}
        textAlign="center"
        spaceHeader="mini"
      >
        {variant === 'contact' ? (
          <StyledContent className={classes.content}>
            <Typography color="textSecondary">{t('contactSection.description')}</Typography>
            <Button
              size="large"
              component={GatsbyLink}
              to={findPagePath('contact')}
              startIcon={<EmailIcon />}
            >
              {t('contactBelt.button')}
            </Button>
          </StyledContent>
        ) : (
          <StyledContent className={classes.newsletterContent}>
            <Newsletter
              className={classes.nativeForms}
              form={formProperties.url}
              formId={formProperties.formId}
            />
          </StyledContent>
        )}
      </BoxWithHeader>

      <div className={clsx(classes.image, classes.arrowImage)}>
        <GatsbyImage
          image={data.arrowImage.childImageSharp.gatsbyImageData}
          alt=""
          title=""
          imgStyle={{ objectFit: 'contain' }}
        />
      </div>
      <div className={clsx(classes.image, classes.leftLineImage)}>
        <GatsbyImage
          image={data.leftLineImage.childImageSharp.gatsbyImageData}
          alt=""
          title=""
          imgStyle={{ objectFit: 'contain' }}
        />
      </div>
      <div className={clsx(classes.image, classes.rightTopLineImage)}>
        <GatsbyImage
          image={data.rightTopLineImage.childImageSharp.gatsbyImageData}
          alt=""
          title=""
          imgStyle={{ objectFit: 'contain' }}
        />
      </div>
      <div className={clsx(classes.image, classes.rightBottomLineImage)}>
        <GatsbyImage
          image={data.rightBottomLineImage.childImageSharp.gatsbyImageData}
          alt=""
          title=""
          imgStyle={{ objectFit: 'contain' }}
        />
      </div>
    </StyledRoot>
  );
};

ContactSection.defaultProps = {
  headerProps: {
    color: 'textPrimary',
    variant: 'h2',
    component: 'h2',
  },
  subheaderProps: {
    color: 'primary',
    variant: 'subtitle1',
    component: 'h3',
  },
  variant: 'contact',
} as Partial<ContactSectionAllProps>;

export default ContactSection;
