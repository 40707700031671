import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { typography, breakpoints, spacing } = theme;
  return {
    [`&.${classes.root}`]: {
      [`& .${classes.description}`]: {
        marginTop: spacing(-1),
        [breakpoints.up('md')]: {
          marginTop: spacing(-1.5),
        },
        '& h1, & h2, & h3, & h4, & h5, & h6': {
          fontWeight: typography.fontWeightMedium,
        },
      },
    },
  };
};
